<template>
  <div class="d-flex align-items-center justify-content-center p-5 m-5" style="height: 51vh">
    <h1>
      THIS FEATURE IS COMING SOON
    </h1>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BImg,
} from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg
  }
}
</script>

<style lang="scss" scoped>

</style>
